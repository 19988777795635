@import './../../../../theme/theme';

.impersonationAlertContainer {
  background-color: #94f0f9;
  margin-top: 142px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 10px;
  font-weight: bold;

  .exitImpersoantionContainer {
    margin-left: 2rem;
    cursor: pointer;
    display: flex;
   }

  .exit {
    margin-left: 7px;
  }
}

.emailErrors {
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  color: $dangerColor;
  margin-left: -15px;
  margin-top: 6px;
  max-width: 208px;
  text-align: center;
}
// Loader for Buttons
.spinner {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid transparent;
  width: 16px;
  height: 16px; 
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
