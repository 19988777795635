.mapboxgl-popup {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  z-index: 10;
}

.mapboxgl-popup .mapboxgl-popup-content {
  padding: 0px;
  // width: 300px;
  border-radius: 10px;
}

.mapboxgl-popup .mapboxgl-popup-content > div {
  margin-top: 0px;
}

.mapboxgl-popup
  .mapboxgl-popup-content
  > div
  > a:first-child
  > div
  > div:first-child {
  background-size: cover;
}

.mapboxgl-popup
  .mapboxgl-popup-content
  > div
  > a:first-child
  > div
  > div:last-child {
  padding: 0px 8px;
}

.mapboxgl-popup .mapboxgl-popup-content > div > div:last-child {
  margin-top: 0px;
  .projectInfo {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }
}

/* hides Improve this map footer from the map */
.mapbox-improve-map {
  display: none;
}
