@import './../../../theme/theme';

.treeCount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: $primaryFontColor;
  div:first-child {
    font-size: 18px;
  }
  span {
    font-weight: 700;
  }
}

.locDetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  gap: 20px;
}

.singleDetail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  position: relative;
  min-width: 50%;
}

.twinDetail {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  width: 100%;
}

.detailTitle {
  color: $primaryFontColor;
  font-weight: 700;
  display: flex;
  gap: 5px;
}

.detailValue {
  color: $primaryFontColor;
}

.detailValue span {
  font-style: italic;
  &.link {
    cursor: pointer;
    text-decoration: underline;
    color: $primaryFontColor;
  }
}

.detailValue a {
  color: $primaryColor;
}

.sliderContainer {
  padding: 16px;
  height: 200px;
  width: 100%;
  background-color: rgb(186, 238, 137);
}

.projectImageSliderContainer {
  position: relative;
  width: 100%;
  // border-radius: 0.6em;
  overflow: hidden;
  > :first-child {
    > :first-child {
      bottom: 2px;
      > div {
        background: rgba(0, 0, 0, 0.4) !important;
        height: 4px !important;
        > div {
          background: rgba(255, 255, 255, 0.8) !important;
        }
      }
    }
  }
}

.singlePl {
  > :first-child {
    > :first-child {
      display: none !important;
    }
  }
}

.projectImageSliderContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: bottom;
  background-position: center center !important;
  background-size: cover !important;
}

.projectImageSliderContentText {
  margin-bottom: 22px;
  margin-left: 12px;
  color: #fff;
  font-size: $fontSixteen;
  font-family: $primaryFontFamily;
  line-height: 1.3;
  padding: 0px 1px;
  // background-color: yellow;
  width: fit-content;
}

.single {
  height: 10px;
  width: 10px;
  border-radius: 100px;
  background-color: rgba(0, 122, 73, 0.5);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  &.singleSelected {
    background-color:  rgba(0, 122, 73, 1);
  }
}

.single:hover {
  background-color: rgba(0, 122, 73, 1);
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.popover {
  position: relative;
  width: fit-content !important;
  svg {
    width: 14px;
  }
  .popoverContent {
    position: absolute;
    left: -80px;
    right: -80px;
    z-index: 10;
    background-color: $backgroundColor;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    border: 2px solid $primaryColor;
    display: none;
    text-transform: none;
    font-weight: normal;
    font-size: $fontSmall;
  }
  &:hover,
  &:active {
    cursor: pointer;
    .popoverContent {
      display: block;
    }
  }
}
