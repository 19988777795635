@import './../../../theme/theme';

.container {
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100vw;
  margin-top: 50vh;
  z-index: 12;
}

.reports_container {
  display: flex;
  gap: 10px;
  width: 100%;
  .reports_description {
    display: flex;
    flex-direction: column;
    gap: 9px;
    a {
      color: $primaryColor;
      font-weight: bold;
    }
  }
}
.modalClose {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 99999;
}
.modalClose svg {
  width: 20px;
}

.modalOpen {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 99999;
}

.modalOpen svg {
  width: 20px;
}

.modalWrapper {
  position: relative;
  width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  animation: fade_in_show 0.5s;
  overflow: hidden;
  :nth-child(2) {
    :first-child {
      bottom: 2px;
      > div {
        background: rgba(0, 0, 0, 0.4) !important;
        height: 4px !important;
        > div {
          background: rgba(255, 255, 255, 0.8) !important;
        }
      }
    }
  }
}

.timeTravelContainer {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
}

// .modal::-webkit-scrollbar {
//   width: 0px;
// }

/* Projects */
.projectContainer {
  display: flex;
  flex-direction: column;
  background-color: $backgroundColor;
  width: 100%;
  padding-bottom: 100px;
  height: auto;
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  transition: transform 0.5s ease-in-out;

  &.mobile-hidden {
    transform: translateY(60vh);
    max-height: 0;
    padding: 0;
    overflow: hidden;
  }
}

.projectContainer::-webkit-scrollbar {
  width: 6px;
}

.projectContainer::-webkit-scrollbar-track {
  background-color: $dividerColor;
  border-top-right-radius: 13px;
}

.projectContainer::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
}

.projectCompleteInfo {
  margin-top: 10px;
  margin-left: 16px;
  margin-right: 16px;
}

.projectSnippetContainer {
  background-color: $backgroundColor;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .projectInfo {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.singleProjectDetails {
  border-radius: 10px;
  background-color: $backgroundColor;
  padding-bottom: 16px;
}

.ratings {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}
.calculatedRating {
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  font-weight: bold;
  color: $primaryFontColor;
}

.ratingButton {
  margin-left: 5px;
}

.projectDescription {
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  color: $primaryFontColor;
  line-height: 21px;
  margin-top: 24px;
}

/* Additional Information */

.projectInfoContainer {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.projectMoreInfoHalfContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 35px;
}

.projectMoreInfoHalf {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.projectMoreInfo {
  width: 100%;
}

.infoTitle {
  font-family: $primaryFontFamily;
  font-size: $fontXSmall;
  color: $primaryFontColor;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
}
.infoText {
  margin-top: 9px;
  font-family: $primaryFontFamily;
  font-size: $fontSmall;
  color: $primaryFontColor;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.infoTextMaxDensity {
  padding-right: 51px;
  display: flex;
  flex-direction: row;
  > div {
    margin-left: 1px;
  }
  > h4 {
    width: 104px;
    margin-left: 4px;
  }
}

.infoTextButton {
  color: $primaryColor;
}
.infoTextButton:hover {
  cursor: pointer;
}

.speciesProgress {
  background-color: $primaryColor;
  height: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  margin-bottom: -6px;
  margin-top: $fontSmall;
}

.projectContactDetails {
  margin-top: 35px;
}

.contactDetailsRow {
  display: flex;
  align-items: center;
  column-gap: 16px;
  > span {
    flex: 1;
    word-wrap: break-word;
  }
}

.projectVideoContainer {
  padding: 20px 0px 10px;
}

.projectImageSliderContainer {
  position: relative;
  margin-top: 10px;
  width: 100%;
  // border-radius: 0.6em;
  overflow: hidden;
  :nth-child(2) {
    :first-child {
      bottom: 2px;
      > div {
        background: rgba(0, 0, 0, 0.4) !important;
        height: 4px !important;
        > div {
          background: rgba(255, 255, 255, 0.8) !important;
        }
      }
    }
  }
}

.projectImageSliderContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: bottom;
  background-position: center center !important;
  background-size: cover !important;
}

.projectImageSliderContentText {
  bottom: 10;
  color: $light;
  font-size: $fontSmall;
  font-family: $primaryFontFamily;
  padding: 14px 12px;
}

.projectDescription > div > span > div {
  color: $primaryColor;
  display: inline;
  padding-left: 5px;
  cursor: pointer;
}

:export {
  highlightBackground: $highlightBackground;
}

@include smTabletView {
  .container {
    padding-bottom: 0px;
  }

  .projectContainer {
    padding-bottom: 80px;
  }
}

@include mdTabletView {
  .container {
    padding-top: 0px;
    width: fit-content;
    padding-left: 14px;
    margin-top: 0px;
  }

  .container::-webkit-scrollbar {
    display: none;
  }

  .projectContainer {
    width: 360px;
    margin: 10px;
    padding-bottom: 16px;
    min-height: calc(100vh - 100px);
    background-color: transparent;
  }
  .reports_container {
    width: 328px;
  }
  .singleProjectDetails {
    margin-top: 20px;
  }
}

// Popover

.popover {
  position: relative;
  width: fit-content !important;
  .popoverContent {
    position: absolute;
    z-index: 10;
    background-color: $backgroundColor;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    border: 2px solid $primaryColor;
    display: none;
    text-transform: none;
    font-weight: normal;
    font-size: $fontSmall;
  }
  &:hover,
  &:active {
    cursor: pointer;
    .popoverContent {
      display: block;
    }
  }
}
