@import '../../../../theme/theme';

.errorContainer {
  min-width: 200px;
  border-radius: 8px;
  background: $backgroundColor;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: auto 1fr;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  pointer-events: all;
  z-index: 2000;
  margin-top: 20px;
}

.closeButton {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-style: none;
  outline: none;
  padding: 16px;
  margin: 0;
  background: none;
  cursor: pointer;
  svg {
    width: 20px;
  }
}

.errorContent {
  padding: 16px 24px 16px 0;
  max-width: 200px;
  font-family: $primaryFontFamily;
  color: $primaryFontColor;
}

.errorContent > a {
  color: $primaryColor;
  cursor: pointer;
}

.errorContent > a:visited {
  color: $primaryColor;
}

@include xsPhoneView {
  .errorContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    border-radius: 0;
  }

  .errorContent {
    padding: 16px 32px 16px 16px;
    max-width: 100%;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    text-align: center;
  }
  .closeButton {
    display: block;
    position: absolute;
    right: 6px;
    padding: 0;
  }
}
:export {
  primaryColor: $primaryColor;
  primaryFontColor: $primaryFontColor;
}
